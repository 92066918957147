import React from "react";
import { Link } from "gatsby";
import logo from "../img/effenti.svg";

const Navbar = class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      navigation: '/'
    };
  }
  toggleClass = (activeItem) => {
    this.setState({
      navigation: activeItem
    });
  };

  toggleHamburger = () => {
    // toggle the active boolean in the state
    this.setState(
      {
        active: !this.state.active
      },
      // after state has been updated,
      () => {
        // set the class in state for the navbar accordingly
        this.state.active
          ? this.setState({
              navBarActiveClass: "is-active"
            })
          : this.setState({
              navBarActiveClass: ""
            });
      }
    );
  };

  render() {
    return (
      <nav
        className="navbar is-transparent"
        role="navigation"
        aria-label="main-navigation"
      >
        <div className="container">
          <div className="navbar-brand">
            <Link to="/" className="navbar-item" title="Logo">
              <img src={logo} alt="Effenti" style={{ width: "88px" }} />
            </Link>
            {/* Hamburger menu */}
            <div
              className={`navbar-burger burger ${this.state.navBarActiveClass}`}
              data-target="navMenu"
              onClick={() => this.toggleHamburger()}
            >
              <span />
              <span />
              <span />
            </div>
          </div>
          <div
            id="navMenu"
            className={`navbar-menu ${this.state.navBarActiveClass}`}
          >
            <div className="navbar-end has-text-centered">
              <Link className="navbar-item navbaritem" activeClassName="is-active" to="/lab-effenti" onClick={() => this.toggleClass('/lab')}>
                Lab-Effenti
              </Link>
              <Link className="navbar-item navbaritem" to="/service-conseil"  activeClassName="is-active"  onClick={() => this.toggleClass('/serviceconseil')}>
                Service-Conseil
              </Link>
              <Link className="navbar-item navbaritem" activeClassName="is-active" to="/code-effenti">
                Code-Effenti
              </Link>
              <Link className="navbar-item navbaritem" activeClassName="is-active" to="/blog">
                Blogue
              </Link>
              <Link className="navbar-item navbaritem" activeClassName="is-active" to="/emplois">
                Emplois
              </Link>
              <Link className="navbar-item navbaritem" activeClassName="is-active" to="/contact">
                Nous joindre
              </Link>
              {/*<a href="https://www.effenti.ca/en" className="navbar-item navbaritem" activeClassName="is-active">*/}
              {/*  en*/}
              {/*</a>*/}
            </div>

          </div>
        </div>
      </nav>
    );
  }
};

export default Navbar;
