import React from 'react'
import {Link} from 'gatsby'

import effentilogo from '../img/effenti.svg'
import { FacebookIcon, TwitterIcon, LinkedInIcon, GithubIcon } from './SvgIcons'

const EffentiFooter = class extends React.Component {

    render() {
        return (
            <footer className="footer has-text-white-ter footer-ctn">
                <div className="content has-text-centered brand-logo">
                    <img
                        src={effentilogo}
                        alt="Effenti"
                        style={{width: '16em', height: '4em'}}
                    />
                </div>
                <div className="content has-text-centered has-text-white-ter btn-group">
                    <div>
                        <Link to="/contact" className="btns contact-btn">Contactez-nous</Link>
                        <Link to="/emplois" className="btns join-us-btn">Rejoindre l'équipe</Link>
                    </div>
                </div>
                <div className="has-text-centered icons">
                    <a href="https://www.facebook.com/Effenti/" target="_blank"  rel="noopener noreferrer"><FacebookIcon class="icon"/></a>
                    <a href ="https://twitter.com/effenti" target="_blank" rel="noopener noreferrer"><TwitterIcon class="icon"/></a>
                    <a href="https://www.linkedin.com/company/effenti-inc/" target="_blank" rel="noopener noreferrer"><LinkedInIcon class="icon"/></a>
                    <a href="https://github.com/effenti" target="_blank" rel="noopener noreferrer"><GithubIcon class="icon"/></a>
                </div>
                <div className="coordinates has-text-centered">
                    <p>418 781-1777</p>
                    <a href="https://goo.gl/maps/144u6xYKy1Cz9RDX9" target="_blank" rel="noopener noreferrer">
                        135-3045 Maricourt, Québec, QC, G1W 0G6
                    </a>

                </div>
            </footer>
        )
    }
}

export default EffentiFooter
